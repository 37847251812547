@charset "UTF-8";

body {
    margin: 0px;
    padding: 0px;
    font-size: 15px;
    /* font-weight: 400; */
    line-height: 1.65;
    text-align: left;
    background-color: #fff;
    font-family: "Raleway",sans-serif !important;
    text-rendering: optimizelegibility;
    overflow-x: hidden;
  }

  .container, .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .topbar-area {
    padding: 10px 0 5px;
    background-color: #ffeede;
}
 .header-logo{
    vertical-align: middle;
 }

 /* .topbar-area .header-logo {
    max-width: 140% !important;
} */

 @media (min-width: 992px) {
    .col-10 {
        width: 10%;
    }
}

.navbar-default {
    background: rgba(0, 0, 0, 0);
    float: left;
}

.main-menu ul.navbar-nav li {
    float: left;
    position: relative;
    margin: 0px 16px;
}

.main-menu ul.navbar-nav li.active a {
    background: rgba(0, 0, 0, 0);
    color: #fff;
    position: relative;
    z-index: 9999999;
}

.main-menu ul.navbar-nav li a {
    background: rgba(0, 0, 0, 0);
    color: #094890;
    letter-spacing: .5px;
    font-size: 12px;
    font-weight: 700;
    padding: 20px 8px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
}

a {
    transition: .3s;
    text-decoration: none;
}

.main-menu ul.navbar-nav > li.active > a {
    background-color: #094890 !important;
}

@media (min-width: 768px) {
    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }
}
