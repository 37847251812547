.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans:400,400i,600,700|Raleway:400,500,600,700,800);

.project-dec h4, .ready-btn:hover, .topbar-left ul li a, a:focus, a:hover {
    text-decoration: none;
}

.awesome-img, .blog-image, .fix, .fun-content, .services-img, .single-blog, .single-page {
    overflow: hidden;
}

.add-actions, .down-btn, .port-icon, .ready-btn {
    cursor: pointer;
}

a:active, a:hover, input:focus, select:focus, textarea:focus {
    outline: 0;
}

.section-headline::after, .sub-head h4::after {
    content: "";
    left: 0;
}

/* @font-face {
    font-family: "Protege Regular";
    src: url(fonts/ec007ae893238105946e47236f9bfd6c.eot);
    src: url(fonts/ec007ae893238105946e47236f9bfd6c.eot?#iefix) format('opentype'),url(fonts/protege-webfont.woff) format('woff'),url(fonts/PROTEGE.TTF) format('truetype'),url(fonts/ec007ae893238105946e47236f9bfd6c.svg) format('svg');
    font-weight: 400;
    font-style: normal;
} */
/* 
@font-face {
    font-family: Samarkan;
    src: url(fonts/SAMARN__.TTF) format('truetype');
    font-weight: 400;
    font-style: normal;
} */

/* @font-face {
    font-family: Protege;
    src: url(fonts/PROTEGE.TTF) format('truetype');
    font-weight: 400;
    font-style: normal;
} */

font-style {
    font-family: "Protege Regular" !important;
    -webkit-font-smoothing: "Protege Regular" !important;
}

.p-content-alter ul, p {
    font-size: 14px;
    line-height: 24px;
    color: #000;
    font-family: 'Open Sans',sans-serif;
    text-align: justify;
    letter-spacing: .5px;
}

.high-text, .service-btn, h1, h2, h3, h4, h5, h6 {
    font-family: Montserrat,sans-serif;
}

body, html {
    height: 100%;
}

.logo a, img {
    height: auto;
}

.floatleft, .header-area-3 .navbar.navbar-default, .navbar.navbar-default {
    float: left;
}

.floatright {
    float: right;
}

.alignleft {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}

.alignright {
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
}

.aligncenter {
    display: block;
    margin: 0 auto 15px;
}

.about-btn, .author-avatar, .footer-icons ul li, .post-img, .project-menu li, .project-menu li a, .pst-content, .section-headline h3, .team-hover li, .top-social ul li, .topbar-left ul li {
    display: inline-block;
}

a:focus {
    outline: solid 0;
}

img {
    max-width: 100%;
}

p {
    margin: 0 0 15px;
}

.p-content-alter ul {
    padding-left: 15px;
}

body, h5 {
    line-height: 22px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 15px;
    color: #004a33;
    font-weight: 700;
}

h1 {
    font-size: 40px;
    line-height: 50px;
    font-family: Raleway,sans-serif;
}

h2 {
    font-size: 38px;
    line-height: 40px;
}

h3 {
    font-size: 30px;
    line-height: 32px;
}

.sub-head p, h4 {
    line-height: 26px;
}

h4 {
    font-size: 24px;
}

#scrollUp, h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
    line-height: 20px;
}

a:focus, a:hover {
    color: #094890;
}

.p-0{
    padding:0  !important;
}
body {
    background: #fff;
    color: #444;
    font-family: Raleway,sans-serif !important;
    font-size: 15px;
    text-align: left;
    overflow-x: hidden;
}

#scrollUp {
    background: #000;
    border-radius: 2px;
    bottom: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    color: #fff;
    height: 34px;
    line-height: 30px;
    opacity: .6;
    right: 15px;
    text-align: center;
    transition: .3s;
    width: 34px;
}

    #scrollUp:hover {
        cursor: pointer;
        background: #094890;
        opacity: 1;
    }

.clear {
    clear: both;
}

.table-list ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

button, button[type=submit], input, input[type=date], input[type=email], input[type=password], input[type=tel], input[type=text], input[type=url], select, textarea {
    -moz-appearance: none;
    box-shadow: none !important;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    background-color: #dfbb59 !important;
    border: 1px solid #dfbb59;
    border-image: none;
    color: #fff !important;
    cursor: default;
    position: relative;
}

div#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow: visible;
    /* background: url(img/logo/preloader.gif) center center no-repeat #fff; */
}

::-moz-selection {
    background: #dfbb59;
    text-shadow: none;
}

::selection {
    background: #dfbb59;
    text-shadow: none;
}

.color, .layer-1-2 .color {
    color: #dfbb59;
    font-weight: 900;
}

.browserupgrade {
    margin: .2em 0;
    background: #ccc;
    color: #000;
    padding: .2em 0;
}

.Reviews-content .active.center .guest-rev a, .Reviews-content .active.center .testi-text p, .Reviews-content .active.center .texti-name h4, .choose-wrapper .service-content h4 a, .choose-wrapper .single-services .service-content p, .copyright-text a, .footer-head p, .header-area-2 .main-menu ul.navbar-nav li a, .main-menu ul.navbar-nav li.active a:focus, .popular-tag.left-blog ul li a:hover, .section-headline.white-headline h3, .single-contact span, .single-services:hover .service-btn, .single-services:hover .service-content h4, .single-services:hover .service-content p, .slogan, .sub-head h4, .sub-head p, .topbar-area-2 .topbar-left ul li a, .video-play.vid-zone:hover i {
    color: #fff;
}

.area-padding {
    padding: 40px 0 20px;
}

.area-padding-2 {
    padding: 70px 0 50px;
}

.padding-2 {
    padding-bottom: 90px;
}

.section-headline {
    margin-bottom: 60px;
    position: relative;
}

    .section-headline h3 {
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .first-item, .project-inner, .reviews-page .single-testi, .section-headline.text-left, .single-page-head {
        margin-bottom: 30px;
    }

.navbar, .slogan {
    margin-bottom: 0;
}

.section-headline::after {
    border: 1px solid #dfbb59;
    bottom: -20px;
    margin: 0 auto;
    position: absolute;
    width: 60px;
    right: 0;
}

.section-headline.text-left h3::after {
    border: 2px solid #dfbb59;
    left: 0;
    right: auto;
}

.section-headline p {
    max-width: 650px;
    font-size: 16px;
    letter-spacing: .5px;
    margin: 0 auto;
    line-height: 26px;
}

.sub-head h4 {
    font-size: 30px;
    position: relative;
    padding-bottom: 30px;
}

    .sub-head h4::after {
        border: 2px solid #dfbb59;
        bottom: 0;
        margin: 0 auto;
        position: absolute;
        width: 20%;
    }

.sub-head p {
    font-size: 17px;
    margin-top: 20px;
}

.mar-row, .pricing-content {
    margin-top: 50px;
}

.bg-gray, .pricing-page-area, .welcome-page-area {
    background: #f9f9f9;
}

.intro-content, .parallax-bg, .parallax-blue-bg {
    position: relative;
    z-index: 1;
}

    .parallax-bg:before, .parallax-blue-bg:before {
        position: absolute;
        height: 100%;
        content: "";
        z-index: -1;
        width: 100%;
        top: 0;
        left: 0;
    }

    .parallax-bg:before {
        background: #111;
        opacity: .7;
    }

    .parallax-blue-bg:before {
        background: #002c57;
        opacity: .9;
    }

.slogan {
    padding: 14px 0;
    font-size: 16px;
}

.topbar-area {
    padding: 10px 0 5px;
}

.topbar-left ul li a {
    color: #fff;
    display: block;
    font-weight: 400;
    margin-right: 10px;
    padding: 6px 11px;
    margin-bottom: 10px;
}

    .topbar-left ul li a i {
        color: #00144d;
        font-size: 16px;
        padding-right: 7px;
    }

.awesome-img, .awesome-img > a, .topbar-right {
    position: relative;
    display: block;
}

.top-social {
    float: right;
    padding: 12px 0;
}

    .top-social ul li.li-a a {
        width: 100%;
        color: #00144d;
        margin-right: 0;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: .6px;
        padding: 6px;
    }

    .top-social ul li a {
        color: #fff;
        margin-left: 0;
        display: block;
        text-align: center;
        font-size: 15px;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        line-height: 25px;
    }

        .left-menu ul li a:hover, .left-menu ul li.active a, .top-social ul li a:hover {
            color: #dfbb59;
            background: #fff;
        }

.left-blog li, .logo, .single-member .team-img a {
    display: block;
}

    .logo a {
        display: inline-block;
        padding: 25px 0;
    }

        .logo a img {
            position: absolute;
            z-index: 999;
            margin-top: -90px;
        }

.breadcrumb .section-headline h3::after, .breadcrumb .section-headline::after, .choose-wrapper .single-services::after, .popular-tag.left-blog ul li a:before, .stick .logo a.sticky-logo img {
    display: none;
}

.navbar-nav li {
  display: block;
    float: left;
    position: relative;
    margin:0px 16px;
}

.navbar-expand-md .navbar-nav .active .nav-link {
    background-color: #094890 !important;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    position: relative;
    z-index: 9999999;
}

.navbar-expand-md .navbar-nav .nav-link{
    background: rgba(0,0,0,0);
    color: #094890;
    letter-spacing: .5px;
    font-size: 12px;
    font-weight: 700;
    padding: 20px 8px;
    text-transform: uppercase;
    font-family: 'Open Sans',sans-serif;
}

.hvr-color a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: #094890 ;
    color: #fff;
    z-index: -9;
    transition: .5s;
}

.about-content h4 ::after, .awesome-img > a::after, .blog-btn::after, .counter-inner h4 ::after, .image-overly::after, .image-scale::after, .intro-content::after, .left-headline::after, .main-menu ul.nav li ul.sub-menu li a::after, .mean-container .mean-bar::after, .parallax-bg-1:before, .project-dec::before, .single-services::after {
    content: "";
}

.hvr-color a:hover:before {
    height: 100%;
    color: #fff;
}

.main-menu ul.navbar-nav li.active a {
    background: rgba(0,0,0,0);
    color: #fff;
    position: relative;
    z-index: 9999999;
}

.header-area.stick .main-menu ul.nav li ul.sub-menu li a:hover, .main-menu ul.nav li:hover ul.sub-menu li a:hover, .main-menu ul.navbar-nav li a:hover {
    color: #fff;
    background: #094890;
}

.navbar {
    border: none;
}

.navbar-default {
    background: rgba(0,0,0,0);
}

    .main-menu ul.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
        background: 0 0;
        color: #fff;
    }

    .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
      color: #fff !important;
      background-color: #094890 !important;
    }

    .header-area-2, .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover, .topbar-area-2 {
        background: 0 0;
    }

.main-menu ul.nav li ul.sub-menu {
    background: #fff;
    border-top: 2px solid #004a33;
    border-left: 1px solid #004a33;
    border-right: 1px solid #004a33;
    border-bottom: 1px solid #004a33;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 115%;
    transition: .3s;
    visibility: hidden;
    width: 260px;
    z-index: -99;
}

.blog-btn, .blog-meta, .clients-support, .faq-details a span.acc-icons, .left-menu ul li.active a, .pagess, .single-blog, .video-text h4 {
    position: relative;
}

.main-menu ul.nav li ul.sub-menu li {
    border-bottom: 1px solid #ddd;
    padding: 0;
    position: relative;
    width: 100%;
}

.main-menu ul.nav li:hover ul.sub-menu {
    top: 100%;
    opacity: 1;
    z-index: 999;
    visibility: visible;
}

.main-menu ul.nav li ul.sub-menu li a {
    color: #333;
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px;
    text-transform: capitalize;
    font-family: 'Open Sans',sans-serif;
}

    .main-menu ul.nav li ul.sub-menu li a span {
        color: #d00b25;
    }

.clients-testi .single-review, .main-menu ul.nav li ul.sub-menu li:last-child {
    border: none;
}

.guest-rev a, .project-dec a, .project-page-area .project-menu li a, .single-services .service-btn, .single-services .service-content p, .stick .header-search input, .stick span.search-button i {
    color: #444;
}

.search-option-5 button.button {
    display: inline-block;
    border: none;
    background: 0 0;
    padding: 0;
}

.search-option {
    background: #fff;
    bottom: -48px;
    color: #444;
    display: none;
    position: absolute;
    right: 20px;
    width: 260px;
    z-index: 99999;
}

    .search-option input {
        background: #fff;
        border: none;
        color: #444;
        display: block;
        float: left;
        font-size: 14px;
        font-style: italic;
        padding: 8px 10px;
        width: 210px;
        height: 44px;
    }

    .header-right-link a.main-search, .search-option button.button {
        background: #dfbb59;
        color: #fff;
        display: inline-block;
        float: right;
    }

    .search-option button.button {
        border: none;
        font-size: 17px;
        line-height: 35px;
        padding: 5px 15px;
        text-align: center;
    }

.header-right-link a.main-search {
    font-size: 16px;
    line-height: 38px;
    position: relative;
    text-align: center;
    margin: 34px 0 34px 15px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.header-area.stick {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 2px #ccc,-2px -2px 2px #ccc;
    background: #fff;
}

    .header-area.stick .logo a {
        display: inline-block;
        height: auto;
        padding: 15px 0;
    }

    .header-area.stick .main-menu ul.navbar-nav li a {
        padding: 15px 8px;
        font-family: 'Open Sans',sans-serif;
        font-weight: 700;
    }

    .header-area.stick .quote-button {
        float: right;
        padding: 13px 5px;
    }

    .header-area.stick .header-right-link a.main-search {
        margin: 24px 0 24px 15px;
    }

    .header-area.stick .main-menu ul.nav li ul.sub-menu li a {
        color: #444;
        display: block;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 15px;
        font-family: 'Open Sans',sans-serif;
    }

.mobile-menu-area {
    background: #f9f9f9;
    padding: 22px 0;
}

.mean-container .mean-bar::after {
    font-size: 21px;
    left: 5%;
    position: absolute;
    top: 12px;
    text-transform: uppercase;
    font-weight: 500;
}

.mean-container a.meanmenu-reveal:hover {
    color: #252525;
}

.mean-container .mean-nav ul {
    list-style-type: none;
    margin: 20px 0;
    padding: 0;
    width: 100%;
}

    .mean-container .mean-nav ul li a {
        background: #f8f8f8;
        color: #444;
        display: block;
        float: left;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
        padding: 13px 10px;
        text-align: left;
        text-decoration: none;
        text-transform: uppercase;
        width: 90%;
    }

.mean-nav ul li.mean-last {
    margin-bottom: 20px;
}

.mean-container .mean-nav ul li a.mean-expand {
    color: #444;
    line-height: 17px;
}

.mean-container .mean-nav ul li {
    width: 99.7%;
}

.intro-content::after {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.slider-content {
    position: absolute;
    right: 0;
    text-align: left;
    top: 50%;
    z-index: 3;
    left: 0;
    margin: -160px auto 0;
}

.layer-1-2 {
    margin: 20px 0;
}

.layer-1-1 p {
    color: #fff;
    font-size: 19px;
    letter-spacing: .6px;
    line-height: 30px;
    max-width: 700px;
}

.layer-1-2 h1 {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    line-height: 60px;
    text-transform: capitalize;
    max-width: 700px;
    letter-spacing: 2px;
}

.about-content h4, .blog-btn, .counter-inner h4, .footer-head h4, .fun_text > h5, .project-dec h4, .ready-btn, .single-contact h5, .suscribe-input button {
    text-transform: uppercase;
}

.ready-btn {
    border: 1px solid #dfbb59;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin-top: 30px;
    padding: 10px 20px;
    text-align: center;
    transition: .4s;
    z-index: 222;
    background: #dfbb59;
    width: 150px;
    border-radius: 3px;
}

    .project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next:hover, .project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev:hover, .ready-btn.right-btn:hover, .ready-btn:hover {
        background: #dfbb59;
        border: 1px solid #dfbb59;
        color: #fff;
    }

    .ready-btn.right-btn {
        margin-left: 15px;
        background: #fff;
        border: 1px solid #fff;
        color: #444;
    }

    .ready-btn.left-btn:hover {
        background: #fff;
        border: 1px solid #fff;
        color: #444;
    }

.quote-area .quote-btn, .service-btn, .service-content h4, .single-services .service-content h4, .texti-name h4, .well-content h4 {
    font-weight: 700;
    text-transform: uppercase;
}

.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next, .intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
    color: #fff;
    font-size: 18px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    text-align: center;
    line-height: 39px;
    opacity: 0;
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 3px;
}

.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev, .team-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
    left: 10%;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
}

.intro-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next, .team-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    right: 10%;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
}

.intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next {
    right: 2%;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    opacity: 1;
}

.intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev {
    left: 2%;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    opacity: 1;
}

    .intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next:hover, .intro-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev:hover {
        color: #fff;
        background: #004a33;
        border: 1px solid #004a33;
    }

.intro-carousel .owl-item .layer-1-2 {
    transform: translateY(100%);
    opacity: 0;
    transition: 1s ease-in-out .3s;
}

.intro-carousel .owl-item .layer-1-1 {
    transform: translateY(100%);
    opacity: 0;
    transition: 1s ease-in-out 1s;
}

.intro-carousel .owl-item .layer-1-3 {
    transform: translateY(100%);
    opacity: 0;
    transition: 1s ease-in-out 1.5s;
}

.intro-carousel .owl-item.active .layer-1-1, .intro-carousel .owl-item.active .layer-1-2, .intro-carousel .owl-item.active .layer-1-3 {
    transform: translateY(0);
    opacity: 1;
}

.intro-carousel .owl-item.active .slider-images img {
    transform: scale(1.1);
    transition: 10s;
}

.Reviews-content, .all-services, .all-well-services, .blog-grid, .blog-grid-fast, .team-page-area .team-member {
    margin-top: -30px;
}

.well-services {
    box-shadow: 0 2px 10px rgba(0,0,0,.1);
    z-index: 1;
    position: relative;
    margin-top: 30px;
}

.faq-content, .fun_text, .image-overly::after, .panel, .table-list, span.base {
    box-shadow: 0 5px 25px rgba(0,0,0,.1);
}

.main-services {
    display: block;
    padding: 20px;
}

.well-icon {
    width: 60px;
    float: left;
    height: 100px;
    font-size: 40px;
    color: #dfbb59;
}

.image-overly, .image-overly::after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.services-img {
    position: relative;
}

.image-overly-text .top-icon {
    font-size: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    display: block;
    color: #dfbb59;
}

.image-overly {
    position: absolute;
    padding: 30px;
    z-index: 1;
}

.image-overly-text {
    position: relative;
    top: 0;
    left: 0;
    z-index: 11;
    opacity: 0;
}

.image-overly::after {
    position: absolute;
    background: rgba(255,255,255,.99);
    z-index: -1;
    opacity: 0;
    transition: .4s;
}

.service-content h4, .well-content h4 {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 0;
    padding: 0 0 10px;
}

.services-img .top-icon {
    font-size: 36px;
    line-height: 56px;
    margin-bottom: 0;
    display: block;
    color: #fff;
    position: absolute;
    left: 20px;
    bottom: 0;
    background: #dfbb59;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 5px 5px 0 0;
}

.service-btn {
    color: #444;
    position: relative;
    font-size: 13px;
    z-index: 1;
}

    .service-btn::after {
        position: absolute;
        content: "\f105";
        font-family: fontAwesome;
        right: -12px;
        top: -2px;
    }

.single-awesome-project:hover .awesome-img img, .well-services:hover .services-img img {
    transform: scale(1);
    transition: .4s;
}

.single-services:hover::after, .well-services:hover .image-overly-text, .well-services:hover .image-overly::after {
    opacity: 1;
    transition: .4s;
}

.blog-btn:hover, .blog-content a h4:hover, .blog-search-option button i:hover, .comments-content-wrap span a, .comments-content-wrap span a:hover, .footer-head .blog-btn, .footer-head .blog-content a h4:hover, .left-blog ul li a:hover, .pst-content p a:hover, .service-content h4:hover, .single-awesome-project:hover .project-dec h4, .single-services .service-content h4 a:hover, .single-services:hover .service-btn:hover, .testi-text a, .well-services:hover .service-btn:hover {
    color: #dfbb59;
}

.all-services .single-services {
    margin-top: 30px;
    padding: 30px;
    position: relative;
    border: 1px solid #dfbb59;
}

.single-services::after, .single-services:hover {
    border: 1px solid #002c57;
}

.service-images {
    font-size: 54px;
    color: #dfbb59;
    display: block;
    margin-bottom: 15px;
    transition: .4s;
    line-height: 50px;
}

.single-services::after {
    background: #002c57;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .4s;
    z-index: -1;
}

.counter-area {
    /* background: url(img/background/bg1.jpg) bottom center/cover no-repeat rgba(0,0,0,0); */
}

.counter-inner {
    background: #f6f6f6;
    padding: 100px 30px 100px 70px;
}

.counter-area .col-md-6 {
    padding-left: 0;
    padding-right: 0;
}

.counter-inner h4 {
    color: #444;
    line-height: 36px;
    font-size: 23px;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;
}

    .about-content h4 ::after, .counter-inner h4 ::after {
        border: 1px solid #dfbb59;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        position: absolute;
        width: 12%;
    }

.counter-count {
    padding: 57px 0;
}

.about-count {
    padding: 90px 80px 90px 70px;
}

.fun-content {
    padding: 20px 0;
    display: block;
}

.fun_text {
    float: left;
    width: 31%;
    text-align: center;
    background: rgba(0,44,87,.95);
    margin-right: 20px;
    padding: 30px 15px;
    border-radius: 5px;
}

    .fun_text:nth-child(3n) {
        margin-right: 0;
    }

    .fun_text span {
        color: #dfbb59;
        display: block;
        font-size: 36px;
        font-weight: 700;
        padding-bottom: 10px;
        line-height: 40px;
    }

    .fun_text > h5 {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
    }

.single-awesome-project {
    float: left;
    overflow: hidden;
    width: 100%;
}

.awesome-img {
    width: 100%;
    height: 100%;
}

    .awesome-img img {
        transform: scale(1.1);
        transition: .4s;
    }

    .add-actions, .awesome-img > a::after {
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

.single-awesome-project:hover .add-actions, .single-awesome-project:hover .awesome-img > a::after {
    opacity: 1;
}

.awesome-img > a::after {
    background: rgba(0,44,87,.95);
    opacity: 0;
    transition: .4s;
}

.add-actions {
    display: block;
    opacity: 0;
    text-align: center;
    transition: .4s;
    z-index: 9999;
}

.port-icon {
    color: #fff;
    display: inline-block;
    top: 41%;
    position: relative;
    font-size: 40px;
}

.project-dec {
    display: block;
    padding: 15px 0 0 20px;
    position: relative;
}

    .project-dec::before {
        position: absolute;
        left: 0;
        top: 20px;
        width: 6px;
        height: 15px;
        background: #dfbb59;
        transition: .5s;
    }

.single-awesome-project:hover .project-dec::before {
    width: 6px;
    height: 80%;
    transition: .5s;
}

.project-dec h4 {
    color: #444;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    cursor: pointer;
}

.author-description h2 a, .project-dec a:hover {
    color: #333;
}

.project-dec p {
    color: #444;
    font-weight: 400;
}

.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next, .project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -12%;
    width: 30px;
    height: 30px;
    border: 1px solid #dfbb59;
    text-align: center;
    background: #dfbb59;
    line-height: 30px;
}

.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
    right: 51px;
}

.project-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
    right: 15px;
}

.down-btn:hover, .project-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next:hover, .project-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev:hover {
    color: #dfbb59;
    background: #fff;
    border: 1px solid #fff;
}

.choose-area {
    /* background-image: url(img/background/bg1.jpg); */
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.left-headline {
    position: relative;
    padding-bottom: 10px;
}

.blog-title, .choose-area .services-all {
    margin-top: -10px;
}

.left-headline::after {
    border: 1px solid #dfbb59;
    bottom: -15px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 60px;
    right: auto;
}

.left-headline p {
    margin: initial;
}

.choose-area .single-services {
    margin-bottom: 0;
    padding: 20px 0 0;
    position: relative;
    border: none;
}

.choose-area .service-images {
    font-size: 40px;
    color: #fff;
    float: left;
    margin-bottom: 0;
    transition: .4s;
    line-height: 40px;
}

.choose-wrapper {
    padding: 70px 100px 70px 0;
}

.choose-inner {
    background: #f9f9f9;
    padding: 110px 30px 110px 100px;
}

.services-all .service-content {
    padding-left: 60px;
}

.single-services .service-content h4 {
    font-size: 18px;
}

.single-testi {
    padding: 30px 20px;
    position: relative;
    border: 1px solid #f5f5f5;
    background: #fff;
}

.footer-icons, .single-post-comments, .team-page-area .single-member, .testi-img, .testimonial-page-area .single-testi, .testimonial-review .single-testi {
    margin-top: 30px;
}

    .testi-img img {
        width: 100%;
        max-width: 80px;
        height: auto;
        border-radius: 50%;
        float: left;
    }

.client-rating, .pb-10, .social-list li {
    padding-bottom: 10px;
}

    .client-rating a {
        display: inline-block;
        color: #fec731;
        font-size: 18px;
    }

.texti-name {
    padding-left: 20px;
    margin-top: 15px;
    display: inline-block;
}

    .texti-name h4 {
        font-size: 15px;
        margin-bottom: 0;
    }

.testi-text p {
    color: #444;
    margin-bottom: 0;
}

.testi-text h5 {
    color: #444;
    font-size: 20px;
}

.testimonial-carousel.item-indicator {
    padding: 30px 0 50px;
}

.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot > span {
    background: #444;
    display: inline-block;
    height: 8px;
    width: 8px;
    -moz-transition: .4s;
    -webkit-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots {
    bottom: 0;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
}

.left-side-tags h4, .testimonial-content {
    margin-bottom: 15px;
}

.pagination > li, .testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot {
    display: inline-block;
    margin: 0 3px;
}

    .testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot.active span {
        background: #dfbb59;
    }

.Reviews-content .active.center .single-testi, .quote-area {
    background: #002c57;
}

.quote-area {
    height: auto;
    width: 100%;
}

.single-contact h5 {
    color: #fff;
    margin-bottom: 15px;
}

.single-contact a {
    display: block;
    margin-bottom: 10px;
}

    .single-contact a i {
        float: left;
        color: #dfbb59;
        font-size: 18px;
        padding-right: 20px;
        line-height: 24px;
    }

.form-row {
    margin-top: 60px;
}

.quote-area #contactForm input {
    border: none;
    border-radius: 0;
    height: 46px;
    margin-bottom: 20px;
}

.quote-area .contact-form textarea#message {
    height: 116px;
    border-radius: 0;
    border: none;
    margin-bottom: 20px;
}

.quote-area .quote-btn {
    background: #dfbb59;
    border: 1px solid #dfbb59;
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    display: block;
    width: 100%;
}

    .quote-area .quote-btn:hover {
        background: #fff;
        color: #dfbb59;
    }

.blog-image {
    display: block;
}

.image-scale {
    position: relative;
    transform: scale(1.1);
    display: block;
    transition: .5s;
}

    .blog-btn::after, .footer-list li a::after, .header-two, .image-scale::after {
        position: absolute;
        left: 0;
    }

    .image-scale::after {
        background: rgba(0,46,91,.3);
        width: 100%;
        height: 100%;
        top: 0;
    }

.blog-content a h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    color: #444;
    line-height: 24px;
    margin-top: -20px;
}

.blog-meta {
    top: -75px;
    background: #dfbb59;
    width: 80px;
    padding: 5px 10px;
    left: 0;
    text-align: center;
    border-radius: 0 5px 5px 0;
}

    .blog-meta span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        padding-right: 10px;
    }

.single-blog:hover .image-scale {
    transform: scale(1);
    transition: .5s;
}

    .single-blog:hover .image-scale::after {
        background: rgba(0,46,91,0);
    }

.single-blog {
    margin-top: 30px;
}

    .comment-respond, .single-blog.b-mar, .single-page-head .review-text, .single-well {
        margin-top: 20px;
    }

.blog-btn {
    display: inline-block;
    font-weight: 700;
    color: #444;
    font-size: 12px;
}

    .blog-btn::after {
        bottom: 0;
        opacity: 0;
        transition: .5s;
        background: #dfbb59;
        width: 0;
        height: 2px;
    }

    .blog-btn:hover.blog-btn::after {
        width: 100%;
        opacity: 1;
        transition: .5s;
    }

.footer-area {
    padding: 70px 0;
    /* background-image: url(img/index/ftbg.jpg); */
    background-size: cover;
}

.footer-head .footer-contacts ul li {
    display: table;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 15px;
}

    .footer-head .footer-contacts ul li i {
        color: #000;
    }

    .footer-head .footer-contacts ul li span {
        display: table-cell;
        padding-left: 10px;
        color: #004a33;
        font-family: Raleway,sans-serif !important;
    }

        .footer-head .footer-contacts ul li span a {
            color: #004a33;
        }

            .copyright a, .footer-head .footer-contacts ul li span a:hover, .footer-list li a:hover {
                color: #add3ff;
            }

.footer-head h4 {
    color: #000;
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 700;
}

.footer-head .single-blog {
    margin-top: 40px;
    position: relative;
    overflow: visible;
}

.footer-head .blog-content a h4 {
    color: #fff;
    margin-bottom: 5px;
    line-height: 22px;
    font-size: 17px;
}

.footer-head .blog-text p, .footer-head hr, .project-menu {
    margin-bottom: 10px;
}

.breadcrumb, .breadcrumb .section-headline, .copyright > p, .team-content p {
    margin-bottom: 0;
}

.footer-icons ul li a {
    color: #fff;
    display: block;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 2px;
}

    .footer-icons ul li a:hover, .team-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next:hover, .team-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev:hover {
        color: #fff;
        background: #dfbb59;
        border: 1px solid #dfbb59;
    }

.footer-contacts p span {
    color: #dfbb59;
    font-weight: 700;
}

.footer-area hr {
    border-top: 1px solid #666;
}

.footer-content {
    display: block;
    overflow: hidden;
}

.suscribe-input input {
    background: 0 0;
    color: #fff;
    font-size: 15px;
    line-height: 24px;
    padding: 11px 15px;
    height: 42px;
    border-radius: 0;
    width: 100%;
    border: 1px solid #777;
}

.about-btn-2, .team-hover li a {
    line-height: 32px;
}

.suscribe-input button, .team-hover li a {
    border: 1px solid #dfbb59;
    background: #dfbb59;
}

.suscribe-input button {
    color: #fff;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 5px;
    transition: .4s;
    margin-top: 15px;
}

    .suscribe-input button:hover {
        background: #fff;
        border: 1px solid #fff;
        color: #444;
        transition: .4s;
    }

.footer-list li a {
    color: #004a33;
    padding: 5px 0 0;
    display: block;
    position: relative;
    font-weight: 700;
}

    .footer-list li a span.social i {
        font-size: 20px;
        margin-right: 10px;
        color: #004a33;
    }

    .footer-list li a i {
        font-size: 9px;
        color: #000;
    }

    .footer-list li a::after {
        top: 5px;
        font-family: nigocio;
        font-size: 12px;
    }

.footer-area-bottom {
    background:#094890;
    border-top: 1px solid #fff;
    padding: 25px 0;
}

.copyright-text a:hover {
    text-decoration: underline;
    color: #dfbb59;
}

.copyright > p {
    color: #fff;
}

.header-two {
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
}

.header-area-2 {
    box-shadow: none;
    border-top: 1px solid rgba(255,255,255,.2);
}

.intro-area-2 .slider-content {
    margin-top: -120px;
}

.header-area-2.stick {
    background: #252525;
}

.about-content h4 {
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 32px;
    font-size: 21px;
    position: relative;
}

.about-content p {
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 24px;
    color: #000;
    font-family: 'Open Sans',sans-serif;
}

.about-btn {
    color: #fff;
    text-transform: capitalize;
    transition: .4s;
    border-radius: 3px;
    font-weight: 700;
    font-size: 20px;
    padding: 15px 30px;
    background: #004a33;
    border: 2px solid #004a33;
    letter-spacing: 1px;
    margin-top: 15px;
}

.price-btn a, .team-content h4, .top-price-inner h4, span.users {
    text-transform: uppercase;
}

.about-btn-1, .about-btn-2 {
    border: 2px solid #392013;
    padding: .5% 2%;
    background: #392013;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    float: right;
    text-decoration: none;
}

.about-btn-1, .about-btn-2, .project-menu li a {
    transition: .3s;
    text-transform: uppercase;
}

.about-btn:hover {
    color: #004a33;
    background: #fff;
    border: 2px solid #004a33;
}

.about-btn-1:hover, .about-btn-2:hover {
    color: #000;
    background: #fff;
    border: 2px solid #000;
}

.project-menu li a {
    color: #444;
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 700;
    letter-spacing: .5px;
    border-radius: 2px;
}

    .project-menu li a.active, .project-menu li a:hover {
        color: #fff;
        text-decoration: none;
        background: #dfbb59;
    }

    .project-menu li a:hover {
        color: #fff;
        text-decoration: none;
    }

.project-content .single-awesome-project {
    margin-top: 40px;
}

.header-area-3, .header-area-3.stick, .pricing-area, .table-list, .team-area, .testimonial-page-area {
    background: #fff;
}

.single-member {
    border: 1px solid #f3f3f3;
}

    .single-member .team-img {
        display: inline-block;
        position: relative;
        overflow: hidden;
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }

.comments-content-wrap span b, .team-hover li, span.post-time {
    margin-right: 5px;
}

    .team-hover li a {
        color: #fff;
        display: block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
    }

        .team-hover li a:hover {
            color: #fff;
            border: 1px solid #dfbb59;
            background: #dfbb59;
        }

.team-hover {
    padding: 15px 0;
    text-align: center;
    transition: .4s;
}

.single-member {
    background: #f5f5f5;
    padding: 30px;
}

.team-content {
    padding: 10px;
}

    .clients-testi .guest-rev, .clients-testi .review-text a, .panel-default > .panel-heading, .single-services-page .marker-list li, .team-content h4 a {
        color: #666;
    }

    .team-content h4 {
        font-size: 17px;
        margin: 10px 0;
        font-weight: 700;
    }

.team-carousel .col-md-4 {
    width: 100%;
}

.team-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next, .team-carousel.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
    color: #fff;
    font-size: 20px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    text-align: center;
    line-height: 48px;
    opacity: 0;
    border: 1px solid #dfbb59;
    background: #dfbb59;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.table-list, .table-list ol li {
    position: relative;
    text-align: center;
}

.team-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-next {
    right: 0;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    opacity: 1;
}

.team-carousel.owl-carousel.owl-theme:hover .owl-controls .owl-nav div.owl-prev {
    left: 0;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    opacity: 1;
}

.price-btn a, .table-list {
    border-radius: 5px;
    transition: .4s;
}

.header-middle-area .header-info {
    width: 33.33%;
    float: left;
    padding: 33px 0;
}

.header-middle-area .header-info-text span {
    display: block;
    font-size: 14px;
}

.header-middle-area .info-simple {
    font-weight: 700;
    color: #777;
    font-size: 12px;
}

.header-middle-area .header-icon {
    float: left;
    margin-right: 10px;
}

.header-middle-area .logo a {
    padding: 25px 0;
}

.header-icon i {
    color: #dfbb59;
    font-size: 34px;
    line-height: 40px;
}

.info-first {
    font-weight: 700;
    color: #444;
}

.header-area-3 {
    border-top: 1px solid rgba(223,187,89,.2);
    box-shadow: none;
}

    .header-area-3 .main-menu ul.navbar-nav li a {
        padding: 20px 15px;
        color: #444;
        margin: 0;
    }

    .header-area-3.stick .main-menu ul.navbar-nav li a {
        padding: 25px 15px;
    }

    .header-area-3 .main-menu ul.navbar-nav li.active > a, .header-area-3 .main-menu ul.navbar-nav > li > a:hover {
        background: #dfbb59;
        color: #fff;
    }

    .header-area-3 .header-right-link a.main-search {
        margin: 10px 0 10px 10px;
    }

.navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.breadcrumb ul li.home-bread, .header-area-3 .navbar-collapse {
    padding-left: 0;
}

.header-area-3.stick .header-right-link a.main-search {
    margin: 15px 0 15px 15px;
}

.top-price-inner {
    border-bottom: 1px solid #ccc;
    padding: 20px;
}

    .top-price-inner h4 {
        color: #444;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 30px;
    }

span.users {
    color: #dfbb59;
    font-size: 13px;
    font-weight: 700;
}

.prices {
    font-size: 26px;
    font-weight: 600;
    color: #444;
    padding-right: 10px;
}

.table-list {
    padding: 20px 0;
}

    .table-list ol li {
        color: #666;
        padding: 12px 0;
    }

.price-btn {
    padding: 30px 20px;
}

    .price-btn a {
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        padding: 12px 30px;
        background: #dfbb59;
        border: 1px solid #dfbb59;
        letter-spacing: 1px;
    }

.table-list > h3 {
    color: #455454;
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
    text-transform: capitalize;
    transition: .4s;
}

.error-easy-text, .faq-page .quote-btn, .left-blog h4, .video-text h4 {
    text-transform: uppercase;
}

.price-btn a:hover {
    background: #fff;
    color: #dfbb59;
    border: 1px solid #dfbb59;
}

span.base {
    position: absolute;
    top: 0;
    left: 0;
    background: #dfbb59;
    color: #fff;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50px;
    font-weight: 700;
}

.parallax-bg-1 {
    position: relative;
    /* background: url(img/background/bg1.jpg) center center/cover no-repeat rgba(0,0,0,0); */
}

    .parallax-bg-1:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .6;
    }

.add-area .video-inner {
    padding: 120px 0;
}

.video-play.vid-zone {
    background: #fff;
    border-radius: 100%;
    display: inline-block;
    font-size: 28px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    width: 70px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    z-index: 999;
    left: 0;
    right: 0;
    margin: -35px auto 0;
    transition: .4s;
}

    .video-play.vid-zone i {
        color: #dfbb59;
        margin-left: 5px;
    }

    .video-play.vid-zone:hover {
        background: #dfbb59;
        color: #fff;
        transition: .4s;
    }

.page-area {
    position: relative;
    /* background: url(img/background/b.jpg) top center/cover no-repeat; */
}

.breadcumb-overlay {
    position: absolute;
    background: rgba(10,27,40,.6);
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
}

.breadcrumb {
    padding: 100px 0;
    background-color: transparent;
}

    .breadcrumb .section-headline h3 {
        font-size: 48px;
        letter-spacing: 2px;
        line-height: 48px;
        text-transform: inherit;
    }

    .breadcrumb ul {
        padding: 10px 0;
    }

        .breadcrumb ul li {
            display: inline-block;
            color: #fff;
            padding: 0 10px 0 20px;
            position: relative;
            font-size: 16px;
        }

            .breadcrumb ul li.home-bread::after {
                position: absolute;
                content: "\f105";
                font-family: fontAwesome;
                right: -10px;
                top: 0;
            }

.video-area .video-inner {
    /* background: url(img/about/vd.jpg) center center/cover no-repeat; */
    position: relative;
    border-radius: 6px;
}

    .video-area .video-inner::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(10,27,40,.2);
    }

.video-area .video-content {
    width: 100%;
    height: 100%;
    min-height: 330px;
}

.video-text h4 {
    font-weight: 600;
    line-height: 32px;
    font-size: 21px;
}

.single-brand-item a {
    border: 1px solid #ddd;
    display: block;
}

.blog-details .blog-content, .faq-details .panel-heading, .popular-tag.left-side-tags.left-blog ul {
    padding: 0;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
}

.panel-default {
    border-color: #ddd;
    border-radius: 0 !important;
}

.panel-group .panel + .panel {
    margin-top: 10px;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ddd;
    background: #fff;
}

.faq-details h4.check-title a {
    color: #666;
    display: block;
    font-weight: 500;
    padding: 10px 10px 10px 40px;
    text-decoration: none;
    background: #fff;
}

.error-btn, .faq-page .quote-btn {
    display: inline-block;
    transition: .4s;
}

.panel-body {
    padding: 15px 15px 0 45px;
}

.faq-details h4.check-title {
    color: #666;
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.faq-details a span.acc-icons::before, .faq-details a.active span.acc-icons::before {
    font-family: nigocio;
    font-size: 16px;
    left: -26px;
    line-height: 39px;
    position: absolute;
    top: -10px;
    text-align: center;
}

.faq-details a span.acc-icons::before {
    color: #666;
    content: "\e874 ";
}

.faq-details a.active span.acc-icons::before {
    color: #dfbb59;
    content: "\e873";
}

.faq-details .panel-heading h4 a.active {
    color: #dfbb59;
    background: #f9f9f9;
}

.faq-content {
    background: #f9f9f9;
    padding: 6% 10%;
    border-radius: 5px;
}

    .faq-content .contact-form textarea#message {
        height: 135px;
    }

.faq-page .quote-btn {
    font-weight: 600;
    color: #fff;
    background: #dfbb59;
    border: 2px solid #dfbb59;
    padding: 10px 20px;
    width: 134px;
    border-radius: 3px;
    margin-top: 10px;
}

    .faq-page .quote-btn:hover {
        color: #dfbb59;
        background: #fff;
        border: 2px solid #dfbb59;
        transition: .4s;
    }

.error-easy-text {
    font-weight: 700;
    font-size: 30px;
}

.high-text {
    font-size: 200px;
    line-height: 200px;
}

.error-bot {
    font-size: 24px;
}

.error-btn {
    background: #dfbb59;
    border: 2px solid #dfbb59;
    color: #fff;
    font-size: 16px;
    padding: 12px 20px;
    text-transform: capitalize;
    margin-top: 30px;
    font-weight: 700;
}

    .error-btn:hover {
        background: #fff;
        border: 2px solid #dfbb59;
        color: #dfbb59;
    }

.contact-details {
    display: block;
    overflow: hidden;
    background: #f5f5f5;
    padding: 40px 30px;
    margin-bottom: 40px;
}

.contact-head {
    background: #f9f9f9;
    padding: 40px 30px;
}

    .contact-head h3 {
        font-weight: 600;
        font-size: 26px;
    }

.contact-inner a i {
    color: #dfbb59;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 15px;
    border-radius: 50%;
}

.contact-inner a {
    color: #666;
    font-size: 16px;
    display: block;
}

.contact-head p {
    font-size: 16px;
}

.contact-form input[type=email], .contact-form input[type=text] {
    border: 1px solid #ccc;
    border-radius: 0;
    height: 44px;
    margin-bottom: 15px;
    padding-left: 20px;
    width: 100%;
}

.contact-form textarea#message {
    border: 1px solid #ccc;
    border-radius: 0;
    height: 180px;
    padding: 20px;
    width: 100%;
}

.contact-page .contact-btn {
    background: #dfbb59;
    border: 2px solid #dfbb59;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    margin-top: 5px;
    padding: 10px 40px;
    transition: .4s;
    width: auto;
    float: left;
    text-transform: uppercase;
    border-radius: 2px;
    font-weight: 700;
}

    .contact-page .contact-btn:hover {
        background: 0 0;
        border: 2px solid #dfbb59;
        color: #dfbb59;
        transition: .4s;
    }

.blog-search-option input {
    padding: 5px 15px;
    width: 80%;
    border: none;
    height: 42px;
}

.blog-search-option {
    margin-bottom: 30px;
    display: block;
    border: 1px solid #ccc;
}

    .blog-search-option button {
        background: 0 0;
        border: none;
    }

        .blog-search-option button i {
            line-height: 16px;
            font-size: 20px;
            padding: 0 10px;
        }

.left-blog h4 {
    border-bottom: 1px solid #ddd;
    color: #444;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 15px 0;
}

.left-blog {
    overflow: hidden;
    padding-bottom: 20px;
}

    .left-blog ul li a {
        color: #444;
        display: block;
        font-size: 16px;
        padding: 10px 15px 10px 25px;
        text-transform: capitalize;
        position: relative;
    }

        .left-blog ul li a:before {
            position: absolute;
            content: "\e87a";
            font-family: nigocio;
            left: 0;
            top: 10px;
            font-size: 14px;
        }

.recent-single-post {
    display: block;
    overflow: hidden;
    padding: 15px 0;
}

.post-img {
    float: left;
    padding: 0 5px;
    width: 35%;
}

.pst-content {
    float: left;
    width: 65%;
    padding-left: 10px;
}

    .pst-content p a {
        color: #444;
        font-size: 15px;
    }

.blog-tags {
    padding: 1px 0;
}

.left-blog li:last-child {
    border-bottom: 0;
}

.left-tags .left-side-tags ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 0;
}

    .left-tags .left-side-tags ul li a {
        padding: 3px 10px;
        width: auto;
        border: 2px solid #dfbb59;
        background: #dfbb59;
        color: #fff;
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
    }

        .left-tags .left-side-tags ul li a:hover {
            border: 2px solid #dfbb59;
            background: #fff;
            color: #dfbb59;
        }

.comments-heading h3, h3.comment-reply-title {
    border-bottom: 1px solid #e8e8e9;
    color: #444;
    font-size: 18px;
    margin: 0 0 20px;
    padding: 0 0 5px;
    text-transform: capitalize;
}

.comments-list ul li {
    margin-bottom: 25px;
}

.comments-list-img {
    float: left;
    margin-right: 15px;
}

.comments-content-wrap {
    color: #444;
    font-size: 14px;
    margin: 0 0 15px 60px;
    padding: 10px;
    position: relative;
}

.blog-content p {
    font-size: 15px;
    letter-spacing: .5px;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 15px;
    border-left: 5px solid #dfbb59;
    font-style: italic;
}

    blockquote p {
        font-size: 16px !important;
        line-height: 28px;
    }

.author-avatar {
    float: left;
    width: 10%;
}

.author-description h2 {
    color: #777;
    font-size: 20px;
    text-transform: uppercase;
}

.comments-content-wrap p {
    color: #444;
    margin-top: 10px;
}

li.threaded-comments {
    margin-left: 50px;
}

span.email-notes {
    color: #42414f;
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
}

.down-btn, .pagination, .project-name span, .support-text a i, .tab-menu ul.nav li {
    display: inline-block;
}

.comment-respond p {
    color: #444;
    margin-bottom: 5px;
}

.comment-respond input[type=email], .comment-respond input[type=text] {
    border: 1px solid #e5e5e5;
    border-radius: 0;
    height: 32px;
    margin-bottom: 15px;
    padding: 0 0 0 10px;
    width: 100%;
}

.comment-respond textarea#message-box {
    border: 1px solid #e5e5e5;
    border-radius: 0;
    max-width: 100%;
    padding: 10px;
    height: 160px;
    width: 100%;
}

.comment-respond .add-btn.contact-btn {
    background: #dfbb59;
    color: #fff;
    display: block;
    font-size: 16px;
    padding: 12px 20px;
    transition: .4s;
    border-radius: 5px;
    width: auto !important;
    margin-top: 20px;
    border: 2px solid #dfbb59;
    text-transform: uppercase;
    font-weight: 700;
}

    .comment-respond .add-btn.contact-btn:hover {
        background: #fff;
        color: #dfbb59;
        border: 2px solid #dfbb59;
    }

.pagination {
    padding-left: 0;
    margin: 30px 0 0;
    border-radius: 4px;
}

    .pagination > li > a, .pagination > li > span {
        position: relative;
        float: left;
        padding: 6px 12px;
        line-height: 1.42857143;
        text-decoration: none;
        color: #fff;
        background-color: #333;
        border: none;
        margin-left: -1px;
        border-radius: 5px;
    }

    .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
        z-index: 3;
        color: #fff;
        background-color: #dfbb59;
        border-color: #dfbb59;
        cursor: default;
        border-radius: 5px;
    }

    .pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
        z-index: 2;
        color: #fff;
        background-color: #dfbb59;
        border-color: #dfbb59;
        border-radius: 2px;
    }

.blog-page-details .left-blog-page {
    margin-top: 0;
    margin-bottom: 30px;
}

.project-2-column .project-single {
    float: left;
    width: 50%;
}

.project-4-column .project-single {
    float: left;
    width: 25%;
}

.project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div {
    background: rgba(0,0,0,0);
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    height: 40px;
    line-height: 38px;
    position: relative;
    text-align: center;
    transition: .5s;
    width: 40px;
    border-radius: 50%;
}

    .project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-prev {
        top: 50%;
        position: absolute;
        left: 3%;
        margin-top: -30px;
    }

    .project-carousel-2.owl-carousel.owl-theme .owl-controls .owl-nav div.owl-next {
        margin-top: -30px;
        position: absolute;
        right: 3%;
        top: 50%;
    }

.project-history {
    background: #dfbb59;
    padding: 20px 30px;
    margin-bottom: 30px;
}

.project-details h4 {
    font-size: 26px;
    font-weight: 600;
}

.project-name ul li {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    margin: 10px 0;
}

.project-name span {
    padding-right: 15px;
    min-width: 110px;
    font-weight: 700;
    font-size: 18px;
}

.project-details p {
    font-size: 14px;
    letter-spacing: .5px;
}

.clients-support {
    width: 100%;
    height: auto;
}

.support-text {
    padding: 30px 30px 20px;
    background: #dfbb59;
    transition: .4s;
}

    .support-text h4 {
        color: #fff;
        font-weight: 700;
    }

    .support-text a {
        margin: 30px 0 15px;
        display: block;
        font-size: 22px;
        color: #fff;
        font-weight: 700;
    }

    .support-text p {
        font-size: 18px;
        line-height: 26px;
        color: #fff;
        font-weight: 700;
    }

    .support-text a i {
        font-size: 30px;
        line-height: 22px;
        padding-right: 8px;
    }

.related-area .project-content {
    margin-top: -40px;
}

.left-menu li:first-child {
    margin: 0 0 2px;
}

.left-contact h4 {
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.left-menu li {
    display: block;
    margin-bottom: 3px;
}

.build-img .page-img {
    padding-bottom: 0;
}

.left-menu ul li a {
    background: #f9f9f9;
    color: #666;
    display: block;
    font-size: 15px;
    font-weight: 700;
    padding: 14px 10px 14px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
}

    .left-menu ul li a:hover::after, .left-menu ul li.active a::after {
        content: "\e87a";
        font-family: nigocio;
        font-size: 14px;
        position: absolute;
        left: 10px;
        top: 15px;
    }

.down-btn {
    color: #444;
    font-size: 14px;
    font-weight: 700;
    padding: 15px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    background: #f5f5f5;
    margin-top: 5px;
    border: 1px solid #f5f5f5;
}

.service-list li, .tab-menu {
    display: block;
}

.services-side-content .down-btn i {
    padding-right: 10px;
    font-size: 22px;
    line-height: 24px;
}

.single-page-head .clients-testi {
    padding: 20px 15px;
    border: 1px solid #dfbb59;
}

.single-page-head .review-img img {
    width: 80px;
    border-radius: 50%;
}

.single-page-head .review-text h4 {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.single-page-head .review-text p {
    color: #666;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .5px;
    font-style: italic;
}

.single-well h3 {
    margin-bottom: 15px;
    font-size: 28px;
}

.page-content h3 {
    font-weight: 600;
    padding: 15px 0 0;
}

.marker-list li, .service-list li {
    color: #666;
    padding-left: 30px;
    position: relative;
}

.single-well a h4 {
    font-weight: 600;
    line-height: 30px;
}

.service-list li::before {
    position: absolute;
    left: 0;
    content: "\f00c";
    font-family: fontawesome;
    top: 0;
    color: #dfbb59;
}

.single-page-head .service-btn {
    width: 100%;
    margin: 20px 0 0;
}

.mar-row .single-well {
    margin-top: 0;
}

.marker-list li {
    margin: 10px 0;
    font-size: 15px;
}

    .marker-list li::after {
        position: absolute;
        content: "\e87f";
        left: 0;
        top: 3px;
        font-family: nigocio;
        color: #dfbb59;
        font-weight: 700;
    }

.tab-menu {
    text-align: center;
}

    .tab-menu ul.nav {
        margin: 0;
        padding: 0;
    }

        .event-content.head-team h5, .tab-menu ul.nav li a {
            padding: 10px 20px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .tab-menu ul.nav li {
            border: none;
        }

            .tab-menu ul.nav li a {
                border: 1px solid #dfbb59;
                background: rgba(0,0,0,0);
                border-radius: 0;
                color: #444;
                display: block;
                margin-right: 5px;
                width: 131px;
            }

.event-content.head-team h5 {
    background: #dfbb59;
    color: #fff;
    font-size: 18px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-50 {
    padding-bottom: 50px;
}

.model-h4 {
    font-family: Samarkan;
    font-size: 32px;
    text-align: center;
    color: #8e4031;
}

.helthy a.activemore, .helthy a:hover.activemore {
    border: 2px solid #d5ca9f;
    padding: .5% 1.9%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}

.helthy a.activemore {
    background: #d5ca9f;
    color: #000;
    float: left;
}

a {
    transition: .3s;
    text-decoration: none;
}
.spacing-form {
    margin:60px 20px 20px 40px;
}

.spacing-form .table tbody tr td {
   padding:40px 20px;
}

.label-form {
    display: inline;
    padding: 20px 0px;
    font-size: 90%;
    font-weight: bold;
    line-height: 2;
    color: #392013;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    font-family: sans-serif;
}

.margin-top{
        margin: 10px 0px;
}

.spacing-form .space-content{
    padding: 20px 10px;
}

.spacing-form .grid-spacing1{
     padding: 30px 10px;
     /*background: #d5ca9f;*/
     background: #ffeede;
     height:80px;
     border:0.1px solid #eee;
     text-align: center;
}

.spacing-form .grid-spacing2{
     padding: 30px 10px;
     height:80px;
     background: #add3ff;
     /*background: #b1cdc4;*/
      border:0.1px solid #eee;
      text-align: center;
}

.padding-none{
    padding:0px !important;
   
}

.paddding-top{
    padding:80px 0px;
}
.topbar-area .header-logo{
    max-width:18% ;
}

.center-class{
  align-items: center;
  display: inline-flex;
}

.subscribe{
   
    border-radius: 30px;
    height: 60px;
    padding: 10px 30px;
     background: #fff;
    border: 1px solid #fff;
    border-radius: 25px;
    color: grey;
    font-size: 15px;
    height: 50px;
    padding: 15px 20px;
    position: relative;
    transition: all .5s ease;
    width: 100%;
}

.join-now{
  align-items:center;
  color: white;
  margin: 0px;
  padding: 0px 16px;
  background: #094890;
  border-radius: 25px;
}

.join-now-button{
  background: #094890;
  padding: 0px;
  margin: 0px;
  border: none;
  font-size: 10px;
}

.main-title {
  font-weight: 600;
  padding-top: 30px;
  font-size: 20pt;
  font-family: "Times New Roman", serif;
  color: #094890 !important;
  background-color: transparent;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-variant-alternates: normal;
  vertical-align: baseline;
  white-space-collapse: preserve;
  text-align: center;
}

.sub-title {
  padding-bottom: 20px;
  font-size: 12pt;
  font-family: "Times New Roman", serif;
  background-color: transparent;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-variant-alternates: normal;
  vertical-align: baseline;
}

.image-size {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

.details {
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
}

.details-inner {
  font-size: 17px;
  font-weight: 500;
}

.name {
  font-size: 22px;
  font-weight: 600;
  color:#094890;
}

.description {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}


.margin-above {
  margin-top: 20px;
}

.success-block {
  margin: 10px 0px 30px 0px;
  padding: 40px 0px;
  border-bottom: 3px dashed #094890;
}

  .success-block:last-child {
      margin: 10px 0px 30px 0px;
      padding: 40px 0px;
      border-bottom: 0px dashed #004a33;
  }

  .sideblock {
    position: fixed;
    top: 40%;
    right: 0;
    padding: 5px 15px;
    /* width: 5%; */
    z-index: 999999;
    -webkit-animation: mymove 10s infinite;
    animation: mymove 10s infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
}

@-webkit-keyframes mymove {
    from {
        top: 0px;
    }

    to {
        bottom: 300px;
    }
}

@keyframes mymove {
    from {
        top: 0px;
    }

    to {
        bottom: 300px;
    }
}

.sideblock ul li {
    list-style: none;
}

.atip {
    border: 5px solid #000;
    padding: 5px;
    background: #fff;
}

.image-frame img {
    width: 60%;
}

.well-services {
    margin-top: 15px;
    margin-bottom: 5px;
}

#abc1 {
    max-width: none !important;
}
@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(1, 1);
    }

    to {
        -webkit-transform: scale(1.5, 1.5);
    }
}

@keyframes zoom {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(1.5, 1.5);
    }
}

.carousel-inner .item > img {
    -webkit-animation: zoom 30s;
    animation: zoom 30s;
}

.arrow-side {
    top: 50%;
    background-image: none !important;
}

    .arrow-side span.fa {
        padding: 13px;
        border: 1px solid #fff;
        border-radius: 5px;
    }

        .arrow-side span.fa:hover {
            background-color: #001f4f;
            color: #fff;
            border: 0;
        }

@media (min-width:768px) {

    .carousel-inner .item > img.sm-img {
        display: block;
    }

    .carousel-inner .item > img.xs-img {
        display: none;
    }
}

@media (max-width:767px) {
    .carousel-inner .item > img {
        min-height: 180px;
    }

        .carousel-inner .item > img.sm-img {
            display: none;
        }

        .carousel-inner .item > img.xs-img {
            display: block;
        }
}


.custom{
    font-size: 1.2em !important;
    color: #012231;
    margin: 15px 0 5px;
    font-weight: 700;
    display: inline;
    padding: 20px 0px;
    font-size: 90%;
    font-weight: bold;
    line-height: 2;
    color: #392013;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    font-family: sans-serif;
    text-transform: uppercase;
}

.bg-custom{
    background-color: #ffeede;
   
}

.navbar-expand-md > .container,
.navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
  -ms-flex-wrap: nowrap;
  /* display: block !important; */
}
 
.submit-btn {
    color: white;
    padding: 6px 12px;
    background-color: #094890;
    border: none;
    border-radius: 5px;
}